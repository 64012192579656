import React from 'react';
import SEO from './SEO';
import { Link } from 'gatsby';
import Header from './Header';
import Footer from './Footer';
import SubFooter from './SubFooter';
import CookieConsent from "react-cookie-consent"
import '../scss/style.scss';

const Layout = props => (
  <>
    <SEO />
    <div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ''}`}>
      <div id="wrapper" className="wrapper">
        <Header />
        {props.children}
      </div>
      <Footer />
      <SubFooter />
    </div>
    <CookieConsent
      location="bottom"
      buttonText="Sutinku"
      declineButtonText="Nesutinku"
      enableDeclineButton
      cookieName="gdpr-cookies"
      buttonStyle={{backgroundColor: '#4CAF50', color: 'white'}}
      expires={150}
    >
      Informuojame, kad šioje svetainėje naudojami slapukai (angl. cookies).
      Sutikdami, paspauskite mygtuką „Sutinku“ arba naršykite toliau.{" "}
      <Link to="/privatumo-politika" id="read-more">
        Skaityti daugiau
      </Link>
    </CookieConsent>
  </>
);

export default Layout;
