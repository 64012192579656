import React from 'react';

const Social = props => {
  return (
    <div className="social">
      <strong><a href="tel:+37046310980" target="_blank">+370 46 310 980</a></strong>
    </div>
  );
};

export default Social;
